<template>
  <Intersect :threshold="0.5" @enter="onIntersect">
    <div ref="root" class="slider horizontal-item-scroller size-full">
      <div class="relative size-full bg-transparent">
        <div class="slider-container flex size-full items-center">
          <FimButton
            v-if="showButtons"
            :disabled="!hasPrev"
            type="raw"
            no-padding
            class="slider-btn-prev absolute top-1/2 z-[12] -translate-y-1/2 gap-0 border-0 disabled:pointer-events-none disabled:!opacity-0"
            :class="{
              'bg-white': isFullscreen,
              'left-0': hasGradient,
              'left-2': !hasGradient,
            }"
            :aria-label="$t('gallery.prev')"
            @click="slidePrev()"
            @touchstart="slidePrev()"
          >
            <slot name="pref-icon">
              <LazyIconFielmannArrowLeft class="size-7" />
            </slot>
          </FimButton>

          <div
            class="relative flex size-full flex-auto overflow-hidden before:absolute before:inset-y-0 before:left-0 before:z-10 before:w-2 after:absolute after:inset-y-0 after:right-0 after:z-10 after:w-2"
            :class="hasGradient && 'wrapper'"
          >
            <div
              ref="scroller"
              class="scroller relative flex w-full snap-x snap-mandatory overflow-y-hidden overflow-x-scroll scrollbar-hide scrolling-touch"
              :class="wrapperClasses"
              @scroll.passive="onScroll"
            >
              <component
                :is="node"
                v-for="(node, index) in clonesLeft"
                :key="`clone-left-${index}`"
                class="relative shrink-0 snap-start"
              />
              <component
                :is="node"
                v-for="(node, index) in slideNodes"
                :key="`slide-${index}`"
                class="relative shrink-0 snap-start"
              />
              <component
                :is="node"
                v-for="(node, index) in clonesRight"
                :key="`clone-right-${index}`"
                class="relative shrink-0 snap-start"
              />
            </div>
          </div>
          <FimButton
            v-if="showButtons"
            :disabled="!hasNext"
            type="raw"
            no-padding
            class="slider-btn-next absolute top-1/2 z-[12] -translate-y-1/2 gap-0 border-0 disabled:pointer-events-none disabled:!opacity-0"
            :class="{
              'bg-white': isFullscreen,
              'right-0': hasGradient,
              'right-2': !hasGradient,
            }"
            :aria-label="$t('gallery.next')"
            @click="slideNext()"
            @touchstart="slideNext()"
          >
            <slot name="next-icon">
              <LazyIconFielmannArrowRight class="size-7" />
            </slot>
          </FimButton>
        </div>

        <div
          v-if="dots && slideCount > 1"
          :class="{ 'absolute bottom-[14px] w-full': dotsStyle === 'bar' }"
          :style="{ '--duration': `${autoplaySpeed * 0.8}ms` }"
        >
          <div
            class="slider-dots relative flex items-center justify-center gap-2"
          >
            <button
              v-for="index in slideCount"
              :key="index"
              :class="dotClasses(index)"
              :data-index="index - 1"
              :disabled="activeIndex === index - 1"
              :aria-label="$t('gallery.goto', { index })"
              @click="slideTo(index - 1)"
              @touchstart="slideTo(index - 1)"
            />
          </div>
        </div>
      </div>
    </div>
  </Intersect>
</template>

<script setup lang="ts">
/* eslint sonarjs/cognitive-complexity: 1 */

import { throttle } from 'throttle-debounce'

const props = defineProps({
  loop: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  autoplay: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  autoplaySpeed: {
    type: Number as PropType<number>,
    default: 7000,
  },
  showButtons: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  dots: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  dotsStyle: {
    type: String as PropType<string>,
    default: 'circle',
    validator: (value: string) => ['circle', 'bar'].includes(value),
  },
  lock: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isFullscreen: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  startAtIndex: {
    type: Number as PropType<number>,
    default: 0,
  },
  hasGradient: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  wrapperClasses: {
    type: Object as PropType<Record<string, boolean>>,
    default: () => ({}),
  },
  slideWidth: {
    type: Number as PropType<number>,
    default: 0,
  },
})

let timeout: ReturnType<typeof setTimeout>
const emit = defineEmits([
  'change',
  'before-change',
  'scroll-start',
  'scroll-end',
  'intersect',
])

const root = ref<HTMLElement>()
const scroller = ref<HTMLElement>()
const slides: Ref<HTMLElement[]> = ref([])
const slideCount = computed(() => slides.value.length)
const activeIndex: Ref<number> = ref(props.startAtIndex)
const slidesPerPage = ref(1)
const computedSlideWidth = ref(props.slideWidth)
const contentRectWidth = ref(0)
const resizeObserver = ref<ResizeObserver>()
const isResizing = ref(false)
const canLoop = computed(() => {
  if (!slideCount.value || !slidesPerPage.value) {
    return props.loop
  }
  return props.loop && slideCount.value > slidesPerPage.value
})
const loopOffset = computed(() => {
  return canLoop.value ? computedSlideWidth.value * slides.value.length : 0
})
const isScrolling = ref(false)
const observerInitialized = ref(false)
const scrollTimeout = ref()
const cloneNextTimeout = ref()
const clonePrevTimeout = ref()
const scope = effectScope()
const slots = useSlots()

const slideNodes = computed(() => getSlotVNodes(slots))

const clonesLeft = computed(() =>
  canLoop.value
    ? slideNodes.value.map((vnode, index) =>
        getVNodeClone(vnode, {
          key: `slide-clone-left-${index}`,
          clone: true,
          'data-clone': 1,
          tabindex: -1,
          preload: false,
          loading: 'lazy',
          // this causes problems when cloned nodes are visible
          // inert: true,
        }),
      )
    : [],
)

const clonesRight = computed(() =>
  canLoop.value
    ? slideNodes.value.map((vnode, index) =>
        getVNodeClone(vnode, {
          key: `slide-clone-right-${index}`,
          clone: true,
          'data-clone': 1,
          tabindex: -1,
          preload: false,
          loading: 'lazy',
          // this causes problems when cloned nodes are visible
          // inert: true,
        }),
      )
    : [],
)

// when slider is scrolled using mouse/touch after it's stopped moving we
// use these ranges to determine which activeIndex to set
const ranges = computed(() => {
  const range = []
  let max = 0

  const start = canLoop.value ? 0 - slideCount.value : 0
  const end = canLoop.value ? slideCount.value * 2 : slideCount.value
  for (let i = start; i < end; i++) {
    range.push({
      index: i,
      target: computedSlideWidth.value * max,
    })
    max++
  }
  return range
})

const setupLayout = () => {
  if (!scroller.value) {
    return
  }
  if (props.slideWidth) {
    slidesPerPage.value = Math.round(
      scroller.value.offsetWidth / props.slideWidth,
    )
  } else {
    const slideElement = slides.value[0]
    const style = window.getComputedStyle(slideElement)
    const marginRight = parseFloat(style.marginRight)
    computedSlideWidth.value = slideElement.offsetWidth + marginRight
    slidesPerPage.value = Math.round(
      scroller.value.offsetWidth / computedSlideWidth.value,
    )
  }
}

const onIntersect = (_: IntersectionObserverEntry, stop: () => void) => {
  emit('intersect', {
    index: activeIndex.value,
    perPage: slidesPerPage.value,
    items: [
      ...slideNodes.value.slice(
        activeIndex.value,
        activeIndex.value + slidesPerPage.value,
      ),
    ],
  })
  stop()
}

const autoplay = () => {
  if (!props.autoplay) {
    return
  }
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    slideNext()
    autoplay()
  }, props.autoplaySpeed)
}

const initialized = ref(false)

const setupSlider = () => {
  if (initialized.value) {
    return
  }
  const allSlides = Array.from(scroller.value?.children ?? []) as HTMLElement[]
  slides.value = allSlides.filter((slide) => !slide.dataset.clone)

  if (!slides?.value.length || !scroller.value) {
    return
  }

  setupLayout()

  slideTo(props.startAtIndex, 'auto')

  resizeObserver.value = new window.ResizeObserver((entries) => {
    // don't want to trigger when width did not change
    const [entry] = entries
    const changed =
      Math.abs(entry.contentRect.width - contentRectWidth.value) > 5

    if (!changed) {
      return
    }

    contentRectWidth.value = entry.contentRect.width

    // don't want to trigger when just starting observing
    if (observerInitialized.value) {
      onResize()
    } else {
      observerInitialized.value = true
    }
  })

  if (scroller.value) {
    resizeObserver.value?.observe(scroller.value)
  }
  initialized.value = true
}

onMounted(async () => {
  scope.run(() => {
    watch(
      slideNodes,
      () => {
        setupSlider()
      },
      { immediate: true },
    )

    watch(
      initialized,
      (next) => {
        if (next) {
          scope.stop()
          autoplay()
        }
      },
      { immediate: true },
    )
  })
})

onScopeDispose(() => {
  if (scope.active) {
    scope.stop()
  }
})

onBeforeUnmount(() => {
  resizeObserver.value?.disconnect()
  if (timeout) {
    clearTimeout(timeout)
  }
})

const slideTo = (index: number, behavior: 'smooth' | 'auto' = 'smooth') => {
  const left = loopOffset.value + index * computedSlideWidth.value
  clearTimeout(timeout)
  scroller?.value?.scrollTo({
    left,
    behavior,
  })
  autoplay()
}

const slideNext = () => {
  slideTo(activeIndex.value + 1)
}

const slidePrev = () => {
  slideTo(activeIndex.value - 1)
}

const hasPrev = computed(() => {
  if (canLoop.value && slideCount.value) {
    return true
  } else if (!canLoop.value) {
    if (slidesPerPage.value >= slideCount.value) {
      return false
    } else if (slidesPerPage.value > 1) {
      return activeIndex.value > 0
    } else {
      return activeIndex.value > 0
    }
  }
  return false
})

const hasNext = computed(() => {
  if (canLoop.value && slideCount.value) {
    return true
  } else if (!canLoop.value) {
    if (slidesPerPage.value >= slideCount.value) {
      return false
    } else if (slidesPerPage.value > 1) {
      return slideCount.value - slidesPerPage.value - activeIndex.value > 0
    } else {
      return activeIndex.value < slideCount.value - 1
    }
  }
  return false
})

const onResize = throttle(25, () => {
  isResizing.value = true // prevent scroll events triggering

  // adjust scroller to keep active slide in view
  setupLayout()
  if (scroller.value) {
    scroller.value.scrollLeft = slides.value[activeIndex.value].offsetLeft
  }

  isResizing.value = false // allow scroll events to continue
})

const onScroll = () => {
  if (isResizing.value) {
    return
  }

  if (!isScrolling.value) {
    isScrolling.value = true
    emit('scroll-start')
  }

  if (scrollTimeout.value) {
    clearTimeout(scrollTimeout.value)
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  scrollTimeout.value = setTimeout(() => {
    if (scroller.value?.scrollLeft !== undefined) {
      // multiple slides need a bigger tolerance to always land on a range
      // single slide needs smaller tolerance to prevent triggering logic below
      // when only partially scrolling using touch
      const tolerance =
        slidesPerPage.value > 1 ? computedSlideWidth.value / 2 : 10
      const $scroller = scroller.value
      const range = ranges.value.find(
        (range) =>
          $scroller.scrollLeft >= range.target - tolerance &&
          $scroller.scrollLeft <= range.target + tolerance,
      )

      if (range?.index !== undefined) {
        if (cloneNextTimeout.value) {
          clearTimeout(cloneNextTimeout.value)
        }

        if (clonePrevTimeout.value) {
          clearTimeout(clonePrevTimeout.value)
        }

        if (canLoop.value && range.index < 0) {
          // clone slide, move to original
          clonePrevTimeout.value = setTimeout(() => {
            activeIndex.value = range.index + slideCount.value
            slideTo(activeIndex.value, 'auto')
          }, 250)
        } else if (canLoop.value && range.index >= slides.value.length) {
          // clone slide, move to original
          cloneNextTimeout.value = setTimeout(() => {
            activeIndex.value = range.index - slideCount.value
            slideTo(activeIndex.value, 'auto')
          }, 250)
        } else {
          // original slide in view, just update index
          activeIndex.value = range.index
        }
      }
      isScrolling.value = false
      emit('scroll-end')
    }
  }, 25)
}

watch(activeIndex, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    emit('change', {
      index: activeIndex.value,
      perPage: slidesPerPage.value,
      items: [
        ...slideNodes.value.slice(
          activeIndex.value,
          activeIndex.value + slidesPerPage.value,
        ),
      ],
    })
  }
})

const dotClasses = (index: number) => {
  if (props.dotsStyle === 'circle') {
    return [
      'overflow-hidden rounded-full bg-gray-500 h-2 w-2 mx-0.5 my-1.5 transition-color',
      activeIndex.value === index - 1 && 'bg-gray-800 cursor-default',
    ]
  } else if (props.dotsStyle === 'bar') {
    return [
      'progress-bar h-[2px] w-6 flex-initial rounded-full relative overflow-hidden cursor-pointer bg-black/20 before:bg-black md:before:bg-white',
      activeIndex.value === index - 1 && 'active cursor-default',
    ]
  }
}

defineExpose({ slideTo, slideNext, slidePrev })
</script>
